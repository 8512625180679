<template>
  <section class="tables" style="margin-top: -60px">
    <div class="row">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light">
            Processus métier :
          </span>
          <span style="font-size: 22px">{{ processus.name }}</span>
        </p>
        {{ processus.capacites }}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des capacités métiers :</h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          show-empty
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(sousCapacites)="data">
            <div class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{
                  data.item.sousCapacites ? data.item.sousCapacites.length : 0
                }}</b-badge
              >
            </div>
          </template>
          <template v-slot:cell(processus)="data">
            <div class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ data.value ? data.value.length : 0 }}</b-badge
              >
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {},
  props: {
    processus: Object,
  },

  data: function () {
    return {
      loading: true,
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "name", label: "Capacités métiers", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        {
          key: "niveau",
          label: "Niveau",
          sortable: true,
        },
        {
          key: "processus",
          label: "Nb Processus",
          sortable: true,
        },
      ],
      tableItems: [],
    };
  },
  created() {
    this.$store
      .dispatch(
        "processusMetier/fetchAllProcessusMetierCapacites",
        this.processus.id
      )
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("processusMetier", ["CAPACITES"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    CAPACITES() {
      this.tableItems = this.CAPACITES.capaciteMetiers;
    },
  },
};
</script>
