var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card m-0"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"p-2 d-flex flex-column"},[(!_vm.items.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"text-white row p-2 rounded my-2 py-3",staticStyle:{"background":"#f2edf3"}},[(!item.sousprocessus.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"50px"}}):_vm._l((_vm.uniqBy(
              item.sousprocessus,
              'id'
            )),function(sousprocessus,appIndex){return _c('div',{key:appIndex,staticClass:"col-12 col-md-3 mb-2"},[(item.sousprocessus.length)?_c('div',{staticClass:"bg-gradient-success rounded h-100 py-2 px-4"},[_c('b-link',{staticClass:"mb-0 text-white",attrs:{"to":{
                  name: 'show-job-process',
                  params: {
                    id: sousprocessus.id,
                    slug: sousprocessus.slug || 'abcde',
                  },
                }}},[_vm._v(_vm._s(sousprocessus.name))]),(sousprocessus.applications.length)?_c('hr',{staticClass:"m-0 p-0 col-12 bg-white"}):_vm._e(),_c('div',{staticClass:"row"},_vm._l((sousprocessus.applications),function(applications,Pindex){return _c('b-link',{key:Pindex,staticClass:"mb-0 col-12 text-white",attrs:{"to":{
                    name: 'show-application',
                    params: {
                      id: applications.id,
                      slug: applications.slug || 'abcde',
                    },
                  }}},[_vm._v(" "+_vm._s(applications.name)+" ")])}),1)],1):_vm._e()])})],2)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }