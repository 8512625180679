<template>
  <section class="tables">
    <div class="col-10 p-0" style="margin-top: -56px">
      <p class="h2 text-secondary">
        <i
          style="font-size: 22px; color: #072648 !important"
          class="mdi mdi-database"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Capacité métier :
        </span>
        <span style="font-size: 22px">{{ items.name }}</span>
        <span style="font-size: 22px"></span>
      </p>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h4 class="card-title mt-4">Liste des processus :</h4>
            <hr />
          </div>
        </div>
        <b-table
          responsive
          :items="items.processus.processus"
          :fields="tableFields"
          bordered
          striped
        >
          <template v-slot:cell(id)="data">
            <a href="#">CM-{{ data.value }}</a>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.value }}
          </template>
          <template v-slot:cell(patrimoine)="data">
            {{ data.value ? data.value.name : '-' }}
          </template>
          <template v-slot:cell(sousprocessus)="data">
            <div class="text-center">
              <b-badge
                pill
                @click="
                  $emit(
                    'showentitieOrganisation',
                    data.item.id,
                    data.item.libelle,
                    'entitie'
                  )
                "
                style="cursor: pointer; background-color: #adb5bd; border-color: #adb5bd"
                >{{
                  data.value ? data.value.length : 0
                }}</b-badge
              >
            </div>
          </template>
          <template v-slot:cell(businessOwner)="data">
            {{
              data.value.firstname + " " + data.value.lastname
            }}
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <!--template v-slot:cell(filling_rate)="data">
         <b-progress max="100" show-progress height="15px">
           <b-progress-bar
             :value="data.value"
             :label="`${data.value}%`"
           ></b-progress-bar>
         </b-progress>
       </template>

       <template v-slot:cell(applications)="data">
         <div class="text-center">
           <b-badge
             variant="primary"
             pill
             @click.prevent="
               showApplicationProcessList = !showApplicationProcessList
             "
             style="cursor: pointer"
             >{{ data.value }}</b-badge
           >
         </div>
       </template>
       <template v-slot:cell(business_owner)="data">
         <div>
           <b-avatar
             v-b-tooltip.hover="data.value.full_name"
             variant="primary"
             size="2rem"
             :src="data.value.img"
             class="mr-1"
           ></b-avatar>
           {{ data.value.name }}
         </div>
       </template>
       <template #cell(actions)="data">
         <table-actions
           :actions="data.value"
           :editMode="data.item.hasOwnProperty('editMode')"
           @showItem="showProcess = !showProcess"
           @transferItem="transferProcess = !transferProcess"
         ></table-actions>
       </template-->
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {},
  data: () => ({
    perPage: 5,
    currentPage: 1,
    tableItems: [
      {
        business_code: "B.1",
        label: "RH",
        patrimony: "Partimoine 1",
        business_owner: {
          name: "S.E",
          full_name: "Steve Ewore",
          img: "https://source.unsplash.com/random/150x150",
        },
        business_capacity: 12,
        attachment_process: "Support",
        filling_rate: 60,
      },
    ],
    tableFields: [
      { key: "id", label: "Code métier", sortable: true },
      { key: "name", label: "Libellé", sortable: true },
      { key: "patrimoine", label: "Patrimoine", sortable: true },
      {
        key: "sousprocessus",
        label: "Processus de rattachement",
        sortable: true,
      },
      // { key: "business_capacity", label: "Capacité Métier", sortable: true },
      { key: "businessOwner", label: "Business Owner", sortable: true },
      {
        key: "taux",
        label: "Taux de remplissage",
        sortable: true,
      },
    ],
  }),
  props: {
    items: Array,
  },
  computed:{
    rows(){
return this.items.processus.processus.length
    }
  }
};
</script>
