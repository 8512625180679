<template>
  <section class="tables" style="margin-top: -60px">
    <div class="row">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light"
            >Processus métiers :
          </span>
          <span style="font-size: 22px">{{ processus.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des applications :</h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          :fields="tableFields"
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-application',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              >{{ data.value }}</b-link
            >
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(po)="data">
            <TableResponsable :responsable="data.value" />
          </template>
          <template v-slot:cell(capaciteSis)="data">
            <div class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ data.value.length }}</b-badge
              >
            </div>
          </template>

          <template v-slot:cell(ref_sa)="data">
            <div>
              <span
                style="text-decoration: underline; color: blue; cursor: pointer"
              >
                SA{{ data.item.id }}
              </span>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge variant="info">{{ data.value }}</b-badge>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    processus: Object,
  },
  data: function () {
    return {
      loading: true,
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "ref_sa", label: "Ref. SA", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "capaciteSis", label: "Capacité SI", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
      ],
      tableItems: [
        {
          ref_sa: "SA1",
          label: "CRM Salesforce",
          patrimoine: "123",
          status: "Etude",
          capaciteSis: "SI-Distrib",
          taux: 10,
          po: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch(
        "processusMetier/fetchAllProcessusMetierApplications",
        this.processus.id
      )
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("processusMetier", ["APPLICATIONS"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    APPLICATIONS() {
      this.tableItems = this.APPLICATIONS.applications;
    },
  },
};
</script>
