<template>
  <div class="row card m-0">
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <div
          v-if="!capacites.filter(({ niveau }) => niveau === 1).length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div
          :key="index"
          v-for="(item, index) in capacites.filter(
            ({ niveau }) => niveau === 1
          )"
        >
          <p class="mt-3 mb-2 h5">{{ item.name }}</p>
          <div
            class="text-white row p-2 rounded my-2 py-3"
            style="background: #f2edf3"
          >
            <div
              v-if="!item.sousCapacites || !item.sousCapacites.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 50px"
            >
              <!-- <i
                class="mdi my-auto ml-auto mdi-information-outline my-auto"
              ></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span> -->
            </div>
            <div
              v-else
              class="col-12 col-md-3 mb-2"
              :key="appIndex"
              v-for="(sousCapacite, appIndex) in uniqBy(
                item.sousCapacites,
                'id'
              )"
            >
              <div
                v-if="item.sousCapacites.length"
                class="bg-gradient-success rounded h-100 py-2 px-4"
              >
                <b-link
                  class="mb-0 text-white"
                  :to="{
                    name: 'show-business-capacity',
                    params: {
                      id: sousCapacite.id,
                      slug: sousCapacite.slug || 'abcde',
                    },
                  }"
                  >{{ sousCapacite.name }}</b-link
                >
                <hr
                  class="m-0 p-0 col-12 bg-white"
                  v-if="sousCapacite.processus.length"
                />
                <div class="row">
                  <b-link
                    v-for="(processus, Pindex) in sousCapacite.processus"
                    :key="Pindex"
                    class="mb-0 col-12 text-white"
                    :to="{
                      name: 'show-job-process',
                      params: {
                        id: processus.id,
                        slug: processus.slug || 'abcde',
                      },
                    }"
                  >
                    {{ processus.name }}
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy } from "lodash";

export default {
  props: {
    capacites: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    uniqBy,
  },
  created() {
    // this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
  },
};
</script>

<style></style>
