<template>
  <div>
    <section class="section position-relative">
      <div class="col-12 p-0">
        <div class="bg-white p-2">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab
              title="Capacités métiers"
              v-if="isAdmin || isSuper || isManager || isArchitect"
            >
              <div class="row mt-3">
                <div
                  class="col-12 d-flex mb-2 flex-wrap justify-content-between"
                >
                  <div class="my-auto d-flex p-0 col-md-auto">
                    <h4 class="card-title mr-1 my-auto">
                      Liste des capacités métiers
                    </h4>

                    <DropDownCustom
                      :items="['Tableau', 'Graphique']"
                      @changeValue="
                        (value) =>
                          this.$store.dispatch(
                            'views/changeCapaciteMiterActiveView',
                            value
                          )
                      "
                      :value="$store.state.views.capaciteMiterActiveView"
                      class="mr-2 mb-2 mb-md-0"
                    />
                  </div>
                  <div class="p-0 col-md-auto justify-content-end">
                    <div>
                      <div class="d-flex">
                        <div class="pr-2">
                          <b-form-input
                            type="text"
                            v-model="name"
                            placeholder="Recherche..."
                            style="width: 250px !important"
                          ></b-form-input>
                        </div>

                        <button
                          @click="addItem"
                          v-if="isAdmin || isSuper || isArchitect"
                          type="button"
                          style="background-color: #198ae3; height: 43px"
                          class="
                            btn
                            h1
                            d-flex
                            text-white
                            align-items-center
                            my-auto
                          "
                        >
                          <i class="mdi mdi-plus mr-2"></i>
                          Nouvelle capacité métier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-0 bg-secondary" />
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body p-0">
                      <BusinessCapacityTable
                        v-if="$store.state.views.capaciteMiterActiveView == 0"
                        :items="filtredCapacites"
                        :loading="loadingData"
                        @editItem="editItem"
                        @showProcesses="showSousCapaciteFunction"
                        @CapaciteList="showSousCapaciteList"
                      />
                      <GraphicView
                        v-else
                        :capacites="filtredCapacites"
                        class="mt-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              title="Processus"
              v-if="
                isAdmin ||
                isSuper ||
                isManager ||
                isArchitect ||
                isCollaborator ||
                isPo
              "
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-3
                  flex-wrap
                "
              >
                <div
                  class="d-flex m-0 p-0 align-items-between col-12 p-0 d-flex"
                >
                  <div class="d-flex">
                    <div class="my-auto mr-2">
                      <h4 class="card-title my-auto">
                        Liste des processus métiers
                      </h4>
                    </div>
                    <DropDownCustom
                      :items="['Tableau', 'Graphique']"
                      @changeValue="
                        (value) =>
                          this.$store.dispatch(
                            'views/changeProcessusMiterActiveView',
                            value
                          )
                      "
                      :value="$store.state.views.processusMiterActiveView"
                      class="mr-2 my-auto"
                    />
                  </div>

                  <button
                    v-if="isAdmin || isSuper || isArchitect"
                    @click="$router.push({ name: 'add-job-process' })"
                    type="button"
                    style="background-color: #198ae3"
                    class="btn ml-auto d-flex text-white align-items-center"
                  >
                    <i class="mdi mdi-plus mr-2"></i>
                    Nouveau processus métier
                  </button>
                </div>
              </div>
              <hr class="bg-secondary mt-2" />

              <VisionTable
                :loadingData="processusLoadingData"
                v-if="$store.state.views.processusMiterActiveView == 0"
                @editItem="$router.push({ name: 'edit-job-process' })"
                @showItem="$router.push({ name: 'show-job-process' })"
                @showCapacites="showCapacitesFunction"
                @showApplications="showApplicationsFunction"
                :items="filteredProcessus"
              />

              <ProcessusGraphicView
                class="mt-3"
                v-else
                :items="filteredProcessus"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>

    <div v-if="showProcesses" to="modals-xyz-548">
      <Modal @close="showProcesses = !showProcesses">
        <processes-list :items="selectedCapacite" />
      </Modal>
    </div>
    <div v-if="showCapacaiteList" to="modals-xyz-548">
      <Modal @close="showCapacaiteList = !showCapacaiteList">
        <CapaciteList :items="selectedCapacitelist" />
      </Modal>
    </div>
    <!-- PROCESSUS -->
    <div v-if="showCapacites" to="modals-xyz-548">
      <Modal @close="showCapacites = !showCapacites">
        <capacites-list :processus="selectedProcessus" />
      </Modal>
    </div>
    <div v-if="showApplications" to="modals-xyz-548">
      <Modal @close="showApplications = !showApplications">
        <application-list :processus="selectedProcessus" />
      </Modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import BusinessCapacityTable from "./components/BusinessCapacityTable.vue";
import Modal from "@/components/custom/Modal.vue";
import ProcessesList from "./components/ProcessesList.vue";
import GraphicView from "./components/GraphicView.vue";
import { mapGetters } from "vuex";
import CapaciteList from "./components/CapaciteList";

// PROCESS
import VisionTable from "./job-process/components/VisionTable.vue";
import CapacitesList from "./job-process/components/CapacitesList.vue";
import ApplicationList from "./job-process/components/ApplicationList.vue";
import ProcessusGraphicView from "./job-process/components/GraphicView.vue";

export default {
  components: {
    CapaciteList,
    DropDownCustom,
    BusinessCapacityTable,
    Modal,
    ProcessesList,
    GraphicView,
    VisionTable,
    ApplicationList,
    CapacitesList,
    //
    ProcessusGraphicView,
  },
  data() {
    return {
      pSelectedView:
        this.$store.state.views.activeView > 1
          ? 0
          : this.$store.state.views.activeView,
      modeShow:
        this.$store.state.views.capaciteMiterActiveView > 1
          ? 0
          : this.$store.state.views.capaciteMiterActiveView,
      searchQuery: {
        name: "",
      },
      selectedCapacite: [],
      newCapacite: {
        name: "",
        sousCapacites: [],
        niveau: "",
        processus: [],
      },
      showProcesses: false,

      loadingData: true,
      selectedCapacitelist: [],
      showCapacaiteList: false,
      businessCapacity: {
        tableFields: [
          { key: "label", label: "Libellé", sortable: true },

          {
            key: "level",
            label: "Niveau",
            sortable: true,
          },
          {
            key: "processes_count",
            label: "Nb Processus",
            sortable: true,
          },
          {
            key: "actions",
            label: "",
            thStyle: { width: "50px !important", minWidth: "50px !important" },
            sortable: false,
          },
        ],
        tableItems: [
          {
            id: "ce53c675-5a7f-4c1d-81e0-f6f0249292e6",
            label: "Libellé 2",
            attachment_capacity: "Lorem",
            level: 1,
            processes_count: 1,
            actions: ["edit", "delete"],
          },
          {
            id: "40c89cc2-a600-48ea-aa8b-4e8d88ae4c80",
            label: "Libellé 1",
            attachment_capacity: "Ipsum",
            level: 2,
            processes_count: 2,
            actions: ["edit", "delete"],
          },
        ],
      },
      dataTable: [],
      name: "",
      // PROCESS
      showCapacites: false,
      selectedProcessus: null,
      showAddJobPrModal: false,
      tableItems: [],
      modalLabel: "",
      showAddVisionModal: false,
      showEditVisionModal: false,
      showVisionModal: false,
      selectedItem: null,
      processusSearchQuery: {
        name: "",
      },
      processusLoadingData: true,
      showApplications: false,
    };
  },
  methods: {
    addItem() {
      if (this.isAdmin || this.isSuper || this.isManager || this.isArchitect) {
        var data = this.dataTable.some((contribute) => contribute.editMode);
        if (data == false) {
          this.dataTable.unshift({ editMode: true });

          this.$store.dispatch("views/changeCapaciteMiterActiveView", 0);
        }
      }
    },
    editItem(data) {
      this.dataTable = this.dataTable.map((contributer) =>
        contributer.id === data.id
          ? { ...contributer, editMode: true, Modify: true }
          : contributer
      );
    },
    onValueChange(value) {
      this.modeShow = value;
      this.pSelectedView = value;
      if (this.modeShow !== value && this.modeShow !== 0) {
        this.modeShow = value;
        this.pSelectedView = value;

        this.$store.dispatch("views/changeView", value).then(() => {
          this.modeShow = value;
          this.pSelectedView = value;
        });
      } else {
        this.$store.dispatch("views/changeView", value).then(() => {
          this.modeShow = value;
          this.pSelectedView = value;
        });
      }
    },

    showSousCapaciteList(members, name) {
      if (members.sousCapacites.length > 0) {
        members = {
          capacite: members.sousCapacites,
          name: name,
        };
        this.selectedCapacitelist = members;
        this.showCapacaiteList = true;
      }
    },
    showSousCapaciteFunction(id, name) {
      this.$store.dispatch("capaciteMetier/fetchCapacité", id).then(() => {
        this.PROCESSUS;
        if (this.PROCESSUS.processus.length > 0) {
          var members = {
            processus: this.PROCESSUS,
            name: name,
            label: true,
          };
          this.selectedCapacite = members;
          this.showProcesses = true;
        }
      });
    },
    // PROCESS
    showCapacitesFunction(item) {
      this.selectedProcessus = item;
      this.showCapacites = true;
    },
    showApplicationsFunction(item) {
      this.selectedProcessus = item;
      this.showApplications = true;
    },
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    pEditItem(item) {
      this.selectedItem = item;
      this.showEditVisionModal = true;
    },
  },
  mounted() {
    // if (this.$store.state.views.activeView > 1) {
    //   this.modeShow = 0;
    //   this.pSelectedView = 0;
    //   this.onValueChange(0);
    // }
  },

  created() {
    // if (this.$store.state.views.activeView > 1) {
    //   this.modeShow = 0;
    //   this.pSelectedView = 0;
    //   this.onValueChange(0);
    // }
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers").then(() => {
      this.loadingData = false;
    });
    // PROCESS
    this.$store
      .dispatch("processusMetier/fetchAllProcessusMetiers")
      .then(() => {
        this.processusLoadingData = false;
      });
  },
  computed: {
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("capaciteMetier", ["PROCESSUS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isManager",
      "isArchitect",
      "isPo",
      "isCollaborator",
    ]),
    filteredProcessus() {
      return this.PROCESSUS_METIERS.filter(
        (processus) =>
          processus.name
            .toLowerCase()
            .indexOf(this.processusSearchQuery.name.toLowerCase()) > -1
      );
    },
    filtredCapacites() {
      return this.dataTable.filter((capacite) =>
        capacite.name
          ? capacite.name.toLowerCase().indexOf(this.name.toLowerCase()) > -1
          : true
      );
    },
  },
  watch: {
    CAPACITE_METIERS() {
      this.dataTable = this.CAPACITE_METIERS.map((capacite) => ({
        ...capacite,
        attachment_capacity: capacite.parent ? capacite.parent.name : "-",
        niveau: capacite.parent ? 2 : 1,
      }));
    },
  },
};
</script>

<style>
.p-2 {
  padding: 0.5rem !important;
}
</style>
