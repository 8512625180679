<template>
  <div>
    <b-table
      :items="items"
      :fields="tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(name)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div class="row col-12 p-0 m-0" v-else>
          <b-form-input
            v-model="newCapacite.name"
            placeholder="Libellé"
            type="text"
            :class="{
              'is-invalid':
                $v.newCapacite.name.$error && $v.newCapacite.name.$dirty,
            }"
          ></b-form-input>
          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCapacite.name.$dirty"
          >
            {{
              !$v.newCapacite.name.required
                ? "Champ obligatoire"
                : !$v.newCapacite.name.minLength
                ? `Le champ doit contenir au moins ${$v.newCapacite.name.$params.minLength.min} caractères.`
                : ""
            }}
          </span>
          <!--  -->
        </div>
      </template>
      <template v-slot:cell(attachment_capacity)="data">
        <div class="" v-if="!data.item.hasOwnProperty('editMode')">
          <span>{{ data.value ? data.value : "-" }}</span>
        </div>
        <v-select
          v-else
          label="text"
          track-by="value"
          :reduce="(capacite) => capacite.value"
          v-model="newCapacite.souscapacite"
          :options="capacitesList(data.item.id)"
          appendToBody
          @input="newCapacite.niveau = newCapacite.souscapacite ? 2 : 1"
          placeholder="Choisir les capacités de rattachement"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>
      <template v-slot:cell(niveau)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value ? data.value : 0 }}
        </span>
        <b-form-input
          :disabled="true"
          v-else
          v-model="newCapacite.niveau"
          placeholder="Niveau"
          type="number"
        ></b-form-input>
      </template>
      <template v-slot:cell(processus)="data">
        <div class="text-center" v-if="!data.item.hasOwnProperty('editMode')">
          <b-badge
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            pill
            @click="$emit('showProcesses', data.item.id, data.item.name)"
            ><span></span>{{ data.value ? data.value.length : 0 }}</b-badge
          >
        </div>
        <multiselect
          v-else
          :disabled="true"
          multiple
          label="text"
          track-by="value"
          v-model="newCapacite.processus"
          :options="processus"
          placeholder="Choisir les processus"
        ></multiselect>
      </template>
      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper || isArchitect ? actions : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @showItem="$emit('showItem', data.item)"
          @editItem="editItem(data.item)"
          @cancelItem="cancelItem(data.item, data.index)"
          @confirmItem="addData(data.item)"
          @deleteItem="deleteItem(data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { uniqBy } from "lodash";
import { PROCESSUS_METIER_URL, CAPACITE_METIER_URL } from "@/helpers/constURL";
import { required, minLength } from "vuelidate/lib/validators"; //validation

export default {
  components: { TableActions },
  props: {
    businessCapacity: Array,
    items: Array,
    loading: Boolean,
  },
  data: () => ({
    sortBy: "label",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    newCapacite: {
      name: "",
      processus: [],
      souscapacite: "",
      niveau: 1,
    },
    capaciteitem: [],
    actions: ["edit", "delete"],
    tableFields: [
      { key: "name", label: "Libellé", sortable: true },
      {
        key: "attachment_capacity",
        label: "Capacité de rattachement",
        sortable: true,
      },
      {
        key: "niveau",
        label: "Niveau",
        sortable: true,
      },
      {
        key: "processus",
        label: "Nb Processus",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },
        sortable: false,
      },
    ],
  }),
  validations: {
    newCapacite: {
      name: {
        required,
        minLength: minLength(5),
      },
    },
  },
  computed: {
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    processus() {
      return this.PROCESSUS_METIERS.map((responsable) => ({
        value: `/api/${PROCESSUS_METIER_URL}/${responsable.id}`,
        text: responsable.name,
      }));
    },
    rows() {
      return this.items.length;
    },
  },
  methods: {
    uniqBy,
    capacitesList(id) {
      return this.CAPACITE_METIERS.filter(
        (capacite) =>
          capacite.niveau === 1 &&
          (id ? parseInt(capacite.id) !== parseInt(id) : true)
      ).map((responsable) => ({
        value: `/api/${CAPACITE_METIER_URL}/${responsable.id}`,
        text: responsable.name,
      }));
    },

    editItem(data) {
      this.$store
        .dispatch("capaciteMetier/fetchAllCapaciteMetiers")
        .then(() => {
          var item = this.items.find((capacite) => capacite.id === data.id);
          var cap = item.parent ? item.parent["@id"] : "";
          this.newCapacite.souscapacite = cap;
          this.newCapacite.name = item.name;
          this.newCapacite.niveau = item.parent ? 2 : 1;
          this.newCapacite.processus = item.processus
            ? item.processus.map((proc) => ({
                value: proc["@id"],
                text: proc.name,
              }))
            : "";
          this.$emit("editItem", data);
        });
    },
    cancelItem: function () {
      if (
        this.newCapacite.souscapacite == "" &&
        this.newCapacite.processus == "" &&
        this.newCapacite.niveau == 1 &&
        this.newCapacite.name == ""
      ) {
        this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
      } else {
        this.newCapacite.niveau = 1;
        this.newCapacite.souscapacite = "";
        this.newCapacite.processus = "";
        this.newCapacite.name = "";
      }
    },
    addData(item) {
      if (this.isAdmin || this.isSuper || this.isArchitect) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (!item.id) {
            var capacite = {
              name: this.newCapacite.name,
              parent: this.newCapacite.souscapacite
                ? this.newCapacite.souscapacite
                : null,
              niveau: this.newCapacite.niveau,
              description: " ",
              isPrincipal: false,
            };
            this.$store
              .dispatch("capaciteMetier/createCapaciteMetier", capacite)
              .then(() => {
                this.$store
                  .dispatch("capaciteMetier/fetchAllCapaciteMetiers")
                  .then(() => {
                    Swal.fire(
                      "La capacité métier a été ajoutée avec succès !",
                      "",
                      "success"
                    );
                    this.newCapacite.niveau = "";
                    this.newCapacite.souscapacite = "";
                    this.newCapacite.processus = "";
                    this.newCapacite.name = "";
                  });
              });
          } else {
            var update = {
              id: item.id,
              parent: this.newCapacite.souscapacite
                ? this.newCapacite.souscapacite
                : null,
              name: this.newCapacite.name,
              niveau: this.newCapacite.niveau,
            };

            this.$store
              .dispatch("capaciteMetier/updateCapacite", update)
              .then(() => {
                this.$store
                  .dispatch("capaciteMetier/fetchAllCapaciteMetiers")
                  .then(() => {
                    Swal.fire(
                      "La capacité métier est mise à jour!",
                      "",
                      "success"
                    );
                    this.newCapacite.niveau = "";
                    this.newCapacite.souscapacite = "";
                    this.newCapacite.processus = "";
                    this.newCapacite.name = "";
                  });
              });
          }
        }
      }
    },
    shallowEqual: function (contributor) {
      return contributor.name === "" &&
        contributor.niveau === "" &&
        contributor.sousCapacites.length == 0 &&
        contributor.processus.length == 0 &&
        contributor.editMode === true
        ? true
        : false;
    },
    removeContributerInputs() {
      var data = this.items.find((contributer) => contributer.Modify == true);
      if (data == null) {
        this.items.splice(0, 1);
      } else {
        this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
      }
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        text: "Cette opération est irréversible et entraînera la suppression de toutes les capacités attachées!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("capaciteMetier/deleteCapaciteMetier", id)
            .then(() => {
              this.items = this.items.filter((item) => item.id !== id);
            });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
  created() {
    // this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    // this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
  },
};
</script>

<style>
.p2 {
  padding: 0.5rem !important;
}
</style>
