<template>
  <div class="row card m-0">
    <!--    <div class="col-12">-->
    <!--      <div class="p-4 d-flex flex-column">-->
    <!--        <div :key="index + 'leas it'" v-for="(processe, index) in processes">-->
    <!--          <p class="mt-3 mb-2 h5">{{ processe.title }}</p>-->
    <!--          <div class="text-white row p-2 rounded my-2 py-3" >-->
    <!--            <div-->
    <!--              :key="ind + 'leas it' + index"-->
    <!--              v-for="(item, ind) in processe.items"-->
    <!--              class="col-12 col-md-3 px-2 mb-2"-->
    <!--            >-->
    <!--              <div class="rounded h-100 py-2 px-4" :class="processe.color">-->
    <!--                <p class="mb-0 font-weight-bold">{{ item.title }}</p>-->
    <!--                <hr class="p-0 m-0 bg-white" />-->
    <!--                <div class="py-1" >-->
    <!--                <p -->
    <!--                v-for="(contentName, contentIdx) in item.content"-->
    <!--                 :key="contentIdx" :id="'contentIdx'+ ind + 'leas it' + index" -->
    <!--                 class=" m-0"-->
    <!--                 style="font-size: small;"-->
    <!--                 >-->
    <!--                 - {{contentName}} -->
    <!--                 </p>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <div
          v-if="!items.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div :key="index" v-for="(item, index) in items">
          <p class="mt-3 mb-2 h5">{{ item.name }}</p>
          <div
            class="text-white row p-2 rounded my-2 py-3"
            style="background: #f2edf3"
          >
            <div
              v-if="!item.sousprocessus.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 50px"
            >
              <!-- <i
                class="mdi my-auto ml-auto mdi-information-outline my-auto"
              ></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span> -->
            </div>
            <div
              v-else
              class="col-12 col-md-3 mb-2"
              :key="appIndex"
              v-for="(sousprocessus, appIndex) in uniqBy(
                item.sousprocessus,
                'id'
              )"
            >
              <div
                v-if="item.sousprocessus.length"
                class="bg-gradient-success rounded h-100 py-2 px-4"
              >
                <b-link
                  class="mb-0 text-white"
                  :to="{
                    name: 'show-job-process',
                    params: {
                      id: sousprocessus.id,
                      slug: sousprocessus.slug || 'abcde',
                    },
                  }"
                  >{{ sousprocessus.name }}</b-link
                >
                <hr
                  class="m-0 p-0 col-12 bg-white"
                  v-if="sousprocessus.applications.length"
                />
                <div class="row">
                  <b-link
                    v-for="(applications, Pindex) in sousprocessus.applications"
                    :key="Pindex"
                    class="mb-0 col-12 text-white"
                    :to="{
                      name: 'show-application',
                      params: {
                        id: applications.id,
                        slug: applications.slug || 'abcde',
                      },
                    }"
                  >
                    {{ applications.name }}
                  </b-link>
                </div>
              </div>
            </div>

            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy } from "lodash";
export default {
  methods: {
    uniqBy,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.job-process .vertical-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.custom-primary-color {
  background: linear-gradient(to right, #007bff, #62aeff);
}
</style>
