<template>
  <section class="tables">
    <div class="col-12 p-0">
      <p class="h2 text-secondary m-3">
        <i
          style="font-size: 22px; color: #072648 !important"
          class="mdi mdi-database"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Capacité Métier {{ items.name }} : Liste des sous capacités
        </span>
        <span style="font-size: 22px"></span>
      </p>
      <hr />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="uniqBy(items.capacite, 'id')"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          bordered
          striped
        >
          <template v-slot:cell(name)="data">
            <span>{{ data.item.name }}</span>
          </template>
          <template v-slot:cell(id)="data">
            <a href="#">CM-{{ data.value }}</a>
          </template>
          <template v-slot:cell(sousCapacites)="data">
            <div class="text-center">
              <b-badge
                style="cursor: pointer; background-color: #adb5bd; border-color: #adb5bd"
                pill
                >{{
                  data.item.sousCapacites ? data.item.sousCapacites.length : 0
                }}</b-badge
              >
            </div>
          </template>
          <template v-slot:cell(processus)="data">
            <div class="text-center">
              <b-badge
                style="cursor: pointer; background-color: #adb5bd; border-color: #adb5bd"
                pill
                >{{ data.item.processus ? data.item.processus.length : 0 }}</b-badge
              >
            </div>
          </template>
        </b-table>
        <b-pagination aria-controls="table-list" align="right"> </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { uniqBy } from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {},
  props: {
    items: Array,
  },

  data: function () {
    return {
      perPage: 10,
      currentPage: 1,
      tableFields: [
        { key: "id", label: "Code métier", sortable: true },

        { key: "name", label: "Libellé", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "niveau", sortable: true },
        {
          key: "processus",
          label: "Nb processus",
          sortable: true,
        },
      ],
      tableItems: [],
    };
  },
  methods: {
    uniqBy,
  },
};
</script>
