<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loadingData"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge variant="info">{{ data.value }}</b-badge>
              </template>
              <template v-slot:cell(parent)="data">
                <b-link
                  v-if="data.value"
                  :to="{
                    name: 'show-job-process',
                    params: {
                      id: data.value.id,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>-</span>
              </template>

              <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template>

              <template v-slot:cell(process_code)="data">
                <div>
                  <span
                    style="
                      text-decoration: underline;
                      color: blue;
                      cursor: pointer;
                    "
                  >
                    PM-{{ data.item.id }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(taux)="data">
                <b-progress max="100" show-progress height="15px">
                  <b-progress-bar
                    :style="`background:${
                      data.value > 20 && data.value <= 70
                        ? '#f5f245'
                        : data.value > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    };font-size:8px;`"
                    :value="data.value"
                    :label="`${data.value}%`"
                  ></b-progress-bar>
                </b-progress>
              </template>

              <template v-slot:cell(deadline)="data">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 10px;
                      background: green;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p style="position: relative; top: 8px; color: white">
                      {{ data.value }}
                    </p>
                  </div>
                </div>
              </template>

              <template v-slot:cell(responsable)="data">
                <TableResponsable :responsable="data.value" />
              </template>

              <template v-slot:cell(businessOwner)="data">
                <TableResponsable :responsable="data.value" />
              </template>

              <template v-slot:cell(task)="data">
                <a :href="data.value.link">{{ data.value.title }}</a>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-job-process',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template v-slot:cell(applications)="data">
                <div class="text-center">
                  <b-badge
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    v-if="data.value.length"
                    @click="$emit('showApplications', data.item)"
                    ><span></span>{{ data.value.length }}</b-badge
                  >
                  <b-badge
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    v-else
                    ><span></span>{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(capaciteMetiers)="data">
                <div class="text-center">
                  <span v-if="data.value">{{ data.value.name }}</span>
                  <span v-else>-</span>
                  <!-- <b-link
                    :to="{
                      name: 'show-job-process',
                      params: {
                        id: data.item.parent.id,
                        slug: data.item.parent.slug || 'abcde',
                      },
                    }"
                    v-if="data.item.parent && data.item.parent.niveau === 1"
                  >
                    {{ data.item.parent.name }}
                  </b-link>
                  <b-link
                    :to="{
                      name: 'show-job-process',
                      params: {
                        id: data.item.sousprocessus.find(
                          (process) => process.niveau === 1
                        ).id,
                        slug:
                          data.item.sousprocessus.find((process) => process.niveau === 1)
                            .slug || 'abcde',
                      },
                    }"
                    v-else-if="
                      data.item.sousprocessus &&
                      data.item.sousprocessus.length &&
                      data.item.sousprocessus.find((process) => process.niveau === 1)
                    "
                  >
                    {{ data.item.sousprocessus.find((process) => process.niveau === 1) }}
                  </b-link>
                  <span v-else>-</span> -->
                </div>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
                  @showItem="
                    $router.push({
                      name: 'show-job-process',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug || 'abcde',
                      },
                    })
                  "
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, TableResponsable },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "process_code", label: "Code processus", sortable: true },
        { key: "name", label: "Processus ", sortable: true },
        { key: "parent", label: "Processus mère", sortable: true },
        { key: "capaciteMetiers", label: "Capacité métier", sortable: true },
        { key: "businessOwner", label: "Business Owner", sortable: true },
        {
          key: "applications",
          label: "Applications",
          sortable: true,
        },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    rows() {
      return this.items.length;
    },
  },
  props: {
    items: Array,
    loadingData: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        text: "Cette opération est irréversible et entraînera la suppréssion de tous les processus rattachés!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("processusMetier/deleteProcessusMetier", id)
            .then(() => {
              this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
            });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
};
</script>
